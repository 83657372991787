import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {Route, Routes} from "react-router-dom"
//componentes
import NaviBar from "./component/NaviBar.jsx";
import FooterComponent from "./component/FooterC.jsx";
//Pages
import Home from "./pages/Home.jsx";
import Contacto from "./pages/Contacto.jsx";
import Colleger from "./pages/Colleger.jsx";
import Conforone from "./pages/Conforone.jsx";
import Spss from "./pages/Spss.jsx";
import Servicio from "./pages/Servicio.jsx";

function App() {
  return (
    <div className="App">
      <header>
        <NaviBar />
      </header>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contacto" element={<Contacto />} />
        <Route path="/colleger" element={<Colleger />} />
        <Route path="/conforone" element={<Conforone />} />
        <Route path="/spss" element={<Spss />} />
        <Route path="/servicios" element={<Servicio />} />
      </Routes>
      <footer>
        <FooterComponent />
      </footer>
    </div>
  );
}

export default App;
