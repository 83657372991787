import Accordion from "react-bootstrap/Accordion";
import Container from "react-bootstrap/Container";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export default function ModalC(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="outline-success" onClick={handleShow}>
        {props.title}
      </Button>

      <Modal size="lg" show={show} onHide={handleClose} animation={false}>
        <Modal.Body className="ratio ratio-16x9">
          <iframe
            src={"https://www.youtube.com/embed/" + props.video}
            title="YouTube video"
            allowfullscreen
          ></iframe>
        </Modal.Body>
      </Modal>
    </>
  );
}
