import "../App.css";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import ModalComponent from "../component/ModalC.jsx";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/esm/Button";
import FormButton from "../component/FormularioColleger";

export default function Colleger() {
  return (
    <div>
      <Container>
        <h1 class="py-3">Colleger</h1>
        <Row className="d-flex justify-content-center">
          <Col className="Texto">
            <h5 class="py-3">
              Aplicación para la gestión docente y administrativa de
              instituciones educativas
            </h5>
            <p class="">
              <strong>Colleger</strong>, más que un software es una solución
              informática integrada que le permite automatizar las operaciones
              de su institución educativa. Además de sus funcionalidades
              docentes, financieras y administrativas, le ofrece una amplia gama
              de informes para el control y seguimiento de la gestión educativa.
              <br></br>
              <br></br>
              {/* <Button variant="outline-success justify-items-center" size="lg">
                <Link
                  className="px-1"
                  to="/contacto"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  Contactenos
                </Link>
              </Button> */}
              Descargue sin compromiso una versión de prueba en la opción debajo
              y vea los siguientes videos de introduccion:
            </p>
          </Col>
          <br></br>
        </Row>
        <FormButton className="mx-auto"></FormButton>
      </Container>
      <hr></hr>
      <Container>
        <Row className="Texto d-flex py-5">
          <Col>
            <h1>Colleger: Parte 1</h1>
            <h5>
              ¿Qué es Colleger? y definición de las opciones de archivos o
              mantenimientos
            </h5>
            <p>
              Qué es Colleger y descripción de Archivos o Mantenimientos.
              Muestra como acceder a Colleger, qué funcionalidades incluye la
              opción Archivos y cómo se registran los datos de estudiantes,
              familias, profesores, articulos y tutores.
            </p>
            <ModalComponent video="KfwACdV77bM" title="Ver video" />
            <br></br>
          </Col>
        </Row>
        <Row className="Texto2 d-flex py-5">
          <Col>
            <h1>Colleger: Parte 2</h1>
            <h5>Registro de opciones docentes y otras tablas secundarias</h5>
            <p>
              Da una visión general de donde localizar las opciones de Archivos
              de tipo docentes: Secciones, Asignaturas, Cursos, Niveles,
              Divisiones yAños escolares. También muestra donde registrar otros
              datos de tablas de configuración. Permite para todas las opciones
              demostrar como crear, editar o realizar cualquier otra
              funcionalidad de estas listas.
            </p>
            <ModalComponent video="XtuVHcZ1ygk" title="Ver video" />
            <br></br>
          </Col>
        </Row>
        <Row className="Texto d-flex py-5">
          <Col>
            <h1>Colleger: Parte 3</h1>
            <h5>Descripción de procesos adminstrativos y financieros</h5>
            <p>
              Muestra el contenido de las funcionalidades administrativas como
              son: Registros de Facturas (Inscripciones, cuotas, servicios
              adicionales), Cobros, Notas de Creditos, Inventarios y otros
              procesos.
            </p>
            <ModalComponent video="E0wv2Uju1i8" title="Ver video" />
            <br></br>
          </Col>
        </Row>
        <Row className="Texto2 d-flex py-5">
          <Col>
            <h1>Colleger: Parte 4</h1>
            <h5>Procesos docentes, reportes y consultas</h5>
            <p>
              Como maneja Colleger los procesos docentes? El video muestra como
              dar inicio al año escolar, como distribuir secciones, asignar
              número de orden, registrar calificaciones cualitativas y
              cuantitativas y registrar novedades o cambios de estatus de
              estudiantes.
            </p>
            <ModalComponent video="u_FUVvPfJu0" title="Ver video" />
            <br></br>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
