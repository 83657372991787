import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Alert from "../component/Alert";

export default function Formulario(props) {
  //showing and unshowing the modal
  const [show, setShow] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const handleClose = () => {
    setShow(false)
    setShowAlert(true)
  };
  const handleHideAlert = () => {
    setShowAlert(false);
  };
  const handleShow = () => setShow(true);

  //handling the form
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [data, setData] = useState({
    sistema: "Conforone", // set the default value to an empty string
  });
  const handleChange = (event) => {
    event.preventDefault();
    setData({ ...data, [event.target.name]: event.target.value });
  };
  //http://localhost/aplsoft/updateCliente.php
  //https://apiform.aplsoftconsulting.com/updateCliente.php
  //sending the data to the server
  const handleSubmit = (event) => {
    event.preventDefault();
    fetch("https://apiform.aplsoftconsulting.com/updateCliente.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((response) => {
        console.log("response: ");
        console.log(response);
      });
    if (
      data.email &&
      data.email.length > 0 &&
      data.nombreEmpresa &&
      data.nombreEmpresa.length > 0
    ) {
      setFormSubmitted(true);
    }
    setShow(false);
    setShowAlert(true);
    setData({ sistema: "Conforone" });
  };

  useEffect(() => {
    if (formSubmitted) {
      fetch("https://apiform.aplsoftconsulting.com/downloadFile.php")
        .then((response) => response.arrayBuffer())
        .then((arrayBuffer) => {
          const blob = new Blob([arrayBuffer], { type: "application/zip" });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = "ConForOne.zip";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        });
    }
    setFormSubmitted(false);
  }, [formSubmitted]);

  return (
    <>
      {showAlert &&(
        <Alert show={showAlert} variant="success" message="Espere un momento la descarga esta iniciando" onClose={handleHideAlert}>
        </Alert>)
      }
      <Button variant="outline-success " onClick={handleShow}>
        Descargar version de prueba
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Datos generales</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Nombre de empresa</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nombre de la empresa"
                name="nombreEmpresa"
                onChange={handleChange}
                autoFocus
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Telefono</Form.Label>
              <Form.Control
                type="text"
                placeholder="Telefono"
                name="telefono"
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Nombre de contacto</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nombre y Apellido de contacto"
                name="contacto"
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Correo electronico</Form.Label>
              <Form.Control
                type="email"
                placeholder="name@example.com"
                name="email"
                onChange={handleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
          <Button variant="success" onClick={handleSubmit}>
            Continuar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
