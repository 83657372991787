import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../App.css";

export default function Contacto() {
  return (
    <div>
      <container className="px-3">
        <div class="py-3">
          <h1>Contactenos</h1>
          <p>Puede contactarnos por los siguientes medios</p>
        </div>
        <div class="justify-content-center">
          <Row>
            <h3>
              Oficinas
            </h3>
            <p>
              Santo Domingo,<br />
              Republica Dominicana
            </p>
          </Row>
          <Row>
            <h3>
              Telefonos:
            </h3>
            <p>
              809-373-8933 <br />
              809-299-7865
            </p>
          </Row>
          <Col>
            <h3>
              Escribanos:
            </h3>
            <p>
              pacosta@aplsoftconsulting.com <br />
              porfirioacosta@spssca.com <br />
              zcastro@aplsoftconsulting.com 
            </p>
          </Col>
        </div>
      </container>
    </div>
  );
}
