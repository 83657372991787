import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import logoSPSS from "../media/ibm-spss-statistics.png";
import logoColleger from "../media/LogoColleger.png";
import logoConforone from "../media/LogoConforone.png";
//componentes
import CarouselComponent from "../component/CarouselC.jsx";
import CardComponent from "../component/CardC.jsx";

function Home() {
  return (
      <body class="bg-light">
        <Row>
          <Col>
            <Container fluid>
              <CarouselComponent />
            </Container>
          </Col>
        </Row>
        <hr />
        <Container>
          <Row className="justify-content-center">
            <Col>
              <p class="Texto px-2">
                Somos una empresa que se dedica a ofrecer soluciones
                tecnológicas. Nos especializamos en desarrollo de software a la
                medida de los requerimientos de nuestros clientes. Tenemos mucha
                experiencia en construir soluciones informáticas, sean estas
                aplicaciones Web, de escritorio o desarrollo de proyectos de
                Business Intelligence.
              </p>
              <br />
              <p class="Texto px-2">
                Estamos disponible para hacerle una demostración de algunas de
                las soluciones desarrolladas o implementadas por nuestra
                empresa. Puedes contactarnos y con gusto le atenderemos.
              </p>
              <br />
              <Button variant="outline-success" size="lg">
                <Link
                  className="px-1"
                  to="/contacto"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  Contactenos
                </Link>
              </Button>
            </Col>
          </Row>
          <hr />
          <Row className="d-flex justify-content-center">
            <Col>
              <CardComponent 
                title="Colleger"
                desc="Sistema de gestion para instituciones educativas"
                direccion="/colleger"
                logo={logoColleger}
              />
            </Col>
            <Col >
              <CardComponent 
                title="Conforone"
                desc="Registro y control de inventario y facturacion"
                direccion="/conforone"
                logo={logoConforone}
              />
            </Col>
            <Col>
              <CardComponent 
                title="IBM SPSS"
                desc="Solucion analitica y estadistica para su negocio"
                direccion="/spss"
                logo={logoSPSS}
              />
            </Col>
          </Row>
        </Container>
      </body>
  );
}

export default Home;
