import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import LogoAPL from "../media/LogoAPL.png";
// import Nav from "react-bootstrap/Nav";
// import Navbar from "react-bootstrap/Navbar";
// import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Image from "react-bootstrap/Image";
import NavDropdown from "react-bootstrap/NavDropdown";

export function NaviBar() {
  return (
    <div class="shadow">
      <Navbar bg="light" variant="light" expand="lg">
        {/* <Container> */}
        <Link className="px-1" to="/" style={{ textDecoration: "none" }}>
          <Navbar.Brand>
            <Image src={LogoAPL} alt="APLSoft Consulting" fluid />
          </Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto justify-content-center">
            <NavDropdown title="Productos" id="navbarScrollingDropdown">
              <NavDropdown.Item>
              <Link
                to="/spss"
                style={{ color: "inherit", textDecoration: "inherit" }}
              >
                SPSS
              </Link>
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item>
              <Link
                to="/colleger"
                style={{ color: "inherit", textDecoration: "inherit" }}
              >
                Colleger
              </Link>
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item>
              <Link
                to="/conforone"
                style={{ color: "inherit", textDecoration: "inherit" }}
              >
                Conforone
              </Link>
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link>
              <Link
                to="/servicios"
                style={{ color: "inherit", textDecoration: "inherit" }}
              >
                Servicios
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link
                to="/contacto"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                Contactenos
              </Link>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
        {/* </Container> */}
      </Navbar>
    </div>
  );
}

export default NaviBar;
