import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ModalComponent from "../component/ModalC.jsx";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
//imagenes
import DeveloperImg from "../media/Developer.png";
import BI from "../media/Business_plan.png";

export default function Servicio() {
  return (
    <div>
      <Container fluid="md" className="px-3">
        <Row className="Texto d-flex py-5">
          <Col md>
            <Image src={DeveloperImg} fluid></Image>
          </Col>
          <Col>
            <h1>Desarrollo de aplicaciones a la medida</h1>
            <p>
              Construimos soluciones de software a la medida de sus
              requerimientos. Sea una aplicación Web o de escritorio, tenemos el
              conocimiento y la experiencia para diseñar, codificar e
              implementar el sistema requerido por su negocio o empresa.
            </p>
            <Button variant="outline-success" size="lg">
              <Link
                className="px-1"
                to="/contacto"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                Contactenos
              </Link>
            </Button>{" "}
            <br></br>
          </Col>
        </Row>
        <hr></hr>
        <Row className="Texto2 d-flex py-5">
          <Col md>
            <h1>Implementamos soluciones de Business Intelligence</h1>
            <p>
              Tenemos mucha experiencia en implementación de soluciones
              analíticas. Desde el diseño del Datawarehouse y los Datamart,
              pasando por la construcción de los procesos de ETL hasta la
              implementación de los reportes y tableros de control o dashboard,
              construimos soluciones a la medida que le ayudarán a su empresa a
              tomar buenas decisiones de negocio.
            </p>
            <Button variant="outline-success" size="lg">
              <Link
                className="px-1"
                to="/contacto"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                Contactenos
              </Link>
            </Button>
            <br></br>
          </Col>
          <Col md>
            <Image src={BI} fluid></Image>
          </Col>
        </Row>
        <hr></hr>
      </Container>
    </div>
  );
}
