import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import Button from "react-bootstrap/Button";
// import { Link } from "react-router-dom";

function FooterC() {
  return (
    <div class="bg-dark">
      <Container className="p-3">
        {/* <Row>
          <Col className="text-white">
            <h6 class="font-weight-bold">PRODUCTOS</h6>
            <p class="font-weight-lighter">SPSS</p>
            <p class="font-weight-lighter">COLLEGER</p>
            <p class="font-weight-lighter">CONFORONE</p>
          </Col>
          <Col className="text-white">
            <h6 class="font-weight-bold">SERVICIOS</h6>
          </Col>
        </Row> */}
        <Row>
          <Col className="text-white">
            <div class="text-center p-3">
              &copy; {new Date().getFullYear()} Copyright:{" "}
              <a
                style={{ textDecoration: "none" }}
                class="text-white"
                href="https://www.aplsoftconsulting.com"
              >
                aplsoftconsulting.com{" "}
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default FooterC;
