import React from "react";
import Carousel from "react-bootstrap/Carousel";
import slide01 from "../media/Slide1.JPG"; 
import slide02 from "../media/Slide2.JPG"; 
import slide03 from "../media/Slide3.JPG"; 
import slide04 from "../media/Slide5.JPG"; 
import slide05 from "../media/Slide3.JPG"; 

function CarouselC() {
  return (
    <div clas="align-items-center">
      <Carousel variant="dark">
        {/* <Carousel.Item>
          <img
            class="d-block w-100"
            src={slide01}
            alt="First slide"
          />
           <Carousel.Caption>
            <h5>First slide label</h5>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </Carousel.Caption> 
        </Carousel.Item> */}
        <Carousel.Item>
          <img
            class="d-block w-100"
            src={slide02}
            alt="Second slide"
          />
          {/* <Carousel.Caption>
            <h5>Second slide label</h5>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <img
            class="d-block w-100"
            src={slide03}
            alt="Third slide"
          />
          {/* <Carousel.Caption>
            <h5>Third slide label</h5>
            <p>
              Praesent commodo cursus magna, vel scelerisque nisl consectetur.
            </p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <img
            class="d-block w-100"
            src={slide04}
            alt="Third slide"
          />
          {/* <Carousel.Caption>
            <h5>Third slide label</h5>
            <p>
              Praesent commodo cursus magna, vel scelerisque nisl consectetur.
            </p>
          </Carousel.Caption> */}
        </Carousel.Item>
        {/* <Carousel.Item>
          <img
            class="d-block w-100"
            src={slide05}
            alt="Third slide"
          />
          <Carousel.Caption>
            <h5>Third slide label</h5>
            <p>
              Praesent commodo cursus magna, vel scelerisque nisl consectetur.
            </p>
          </Carousel.Caption> 
        </Carousel.Item> */}
      </Carousel>
    </div>
  );
}

export default CarouselC;
