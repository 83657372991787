import React from 'react'
import Alert from 'react-bootstrap/Alert'
import { useState } from 'react'

export default function Alerts(props) {
    const [showAlert, setShowAlert] = useState(true);

    const handleHideAlert = () => {
      setShowAlert(false);
    };
  return (
    <Alert variant={props.variant}  onClose={handleHideAlert}>
        {props.message}
    </Alert>
  )
}
