import "../App.css";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
// import ModalComponent from "../component/ModalC.jsx";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/esm/Button";
import imagenPrueba from "../media/logoprueba.png";
import conforone1 from "../media/conforoneImage1.png";
import conforone2 from "../media/conforone2.png";
import Image from "react-bootstrap/esm/Image";
import FormButton from "../component/Formulario";


export default function Colleger() {
  return (
    <div>
      <Container>
        <Row className="py-5 d-flex justify-content-center">
          <Col md>
            <Image className="pt-3" src={imagenPrueba} fluid></Image>
            <h1 class="py-3">ConForOne</h1>
          </Col>
          <Col className="Texto">
            <h5 class="py-3">Aplicación para la gestión de negocios</h5>
            <p class="">
              <strong>ConForOne</strong>: Es nuestra solución ERP para su empresa o negocio.
              Abarca los módulos de <strong>Control de Inventario, Compras, Ventas,
              Cuentas por Cobrar, Cuentas por Pagar, Bancos y Contabilidad. </strong> 
              Puede obtener su beneficios por suscribirse como un servicio o
              adquirir licencias de uso perpetuo. Descargue sin compromiso una
              versión de prueba en la opción debajo.
              <br></br>
              <br></br>
            </p>
          </Col>
          <br></br>
        </Row>
        <FormButton></FormButton>
      </Container>
      <Image className="pt-3" src={conforone1} fluid></Image>
      <Image className="pt-3" src={conforone2} fluid></Image>
      <hr></hr>
    </div>
  );
}
