import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";

function CardC(props) {
  return (
    <Container fluid>
      <Card  className="d-flex shadow-lg">
        <Card.Img variant="top" style={{ height: '300px'}} src={props.logo} />
        <Card.Body>
          <Card.Title>{props.title}</Card.Title>
          <Card.Text>{props.desc}</Card.Text>
          <Button variant="outline-success">
            <Link
              className="px-1"
              to={props.direccion}
              style={{ color: "inherit", textDecoration: "none" }}
            >
              Ver mas
            </Link>
          </Button>
        </Card.Body>
      </Card>
      <br></br>
    </Container>
  );
}

export default CardC;
