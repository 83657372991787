import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Button from "react-bootstrap/esm/Button";
import { Link } from "react-router-dom";

export default function Spss() {
  return (
    <div>
      <Container>
        <h1 class="py-3">IBM SPSS</h1>
        <Row className="d-flex justify-content-center">
          <Col className="Texto">
            <h5 class="py-3">
              Solucion analitica y estadistica para su negocio
            </h5>
            <p class="">
              La plataforma de software IBM® SPSS® ofrece análisis estadístico
              avanzado, una amplia biblioteca de algoritmos de machine learning,
              análisis de texto, extensibilidad de código abierto, integración
              con big data y un fácil despliegue en las aplicaciones.
              <br></br>
              <br></br>
              Su facilidad de uso, flexibilidad y escalabilidad hacen que SPSS
              sea accesible para usuarios con cualquier nivel de conocimiento.
              Además, es adecuado para proyectos de todos los tamaños y niveles
              de complejidad, y puede ayudarle a usted y a su organización a
              encontrar nuevas oportunidades, mejorar la eficiencia y minimizar
              el riesgo.
              <br></br>
              <br></br>
              <Button variant="outline-success justify-items-center" size="lg">
                <Link
                  className="px-1"
                  to="/contacto"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  Contactenos
                </Link>
              </Button>
            </p>
          </Col>
          <br></br>
        </Row>
      </Container>
    </div>
  );
}
